.a-icon {
  &__add,
  &__remove {
    color: $white_color;
    border-radius: 50%;
    border: 0;
    background: $green_color;
    font-size: 1.5rem;
    font-weight: 700;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    @include boxshadow();
    line-height: 10px;
    padding: 0;
    cursor: pointer;
    i {
      font-size: 16px;
      color: $white_color;
      -webkit-text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      -moz-text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    }
  }
  &__remove {
    background: $light-grey_color;
  }
  &__check {
    width: 40px;
    height: 40px;
    background-color: $green_color;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    @include boxshadow();
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -13px;
      margin-left: -6px;
      width: 9px;
      height: 18px;
      border: solid $white_color;
      border-radius: 4px;
      border-width: 0 4px 4px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    i {
      color: $white_color;
    }
  }
}
