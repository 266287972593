.p-summary {
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 6rem;
}

.loader {

  &--hidden {
    visibility: hidden;
  }

  &--visible {
    visibility: visible;
  }
}

.loader-label {
  margin-left: 5px;
}

.bold {
  font-weight: bold;
}

.captcha {
  display: flex;
  justify-content: center;
}
