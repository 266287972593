.base-container-input-coreoz {
  margin: 0 0 12px;
  clear: both;
  .base-input-coreoz,
  .react-phone-number-input input {
    display: inline-block;
    width: 100%;
    height: 41px;
    border: 0;
    border-radius: 10px;
    font-size: 0.7rem;
    color: $grey_color;
    font-weight: 700;
    font-family: 'Raleway';
    padding: 10px 1.5rem 10px 10px;
    @include boxshadow();
    background: $white_color;
  }
  .label-input-oav--error + .react-phone-number-input input,
  .base-input-coreoz--error,
  .baselabel-input-coreoz--error + div input {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(255, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 6px 0px rgba(255, 0, 0, 0.3);
    box-shadow: 0px 3px 6px 0px rgba(255, 0, 0, 0.3);
  }

  .base-error-form {
    right: 0;
    left: auto;
    margin-right: 10px;
    text-align: right;
  }
}

.a-input {
  &__zip {
    width: 46%;
    float: left;
    .base-input-coreoz {
      font-family: sans-serif;
    }
  }
  &__city {
    width: 46%;
    float: right;
  }
  &__birthday {
    @include clearfix;
    .base-container-input-coreoz {
      width: 60%;
      float: left;
    }
    .base-input-coreoz {
      font-family: sans-serif;
    }
    &--age {
      display: inline-block;
      float: right;
      width: 35%;
      color: $grey_color;
      font-size: 1rem;
      font-weight: 200;
      padding-top: 35px;
      text-transform: lowercase;
    }
  }
  &__trip {
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 100px;
      width: 150px;
      background: url('../img/airplane.svg') center center no-repeat;
      background-size: 100%;
      position: absolute;
      right: -23px;
      top: -45px;
    }
  }
  &__tripdate {
    .base-input-coreoz {
      font-family: sans-serif;
    }
  }
  &__areatel {
    @include boxshadow();
    border-radius: 10px;
    margin: 33px 0 0;
    height: 42px;
  }
  &__areacode {
    width: 20%;
    float: left;
    height: 100%;
    .base-label-input-coreoz {
      text-indent: -9999px;
      margin-bottom: 1px;
      height: 100%;
    }
    .base-input-coreoz {
      border-radius: 10px 0 0 10px;
      box-shadow: none;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1rem;
      color: $light-grey_color;
    }
    .base-select-coreoz {
      &:before {
        top: 18px;
        color: $light-grey_color;
      }
    }
  }
  &__tel {
    .PhoneInput input {
      font-family: sans-serif;
    }
  }
  &__bin {
    position: relative;
    @extend .hasicon;
    &:before {
      @extend .icon-card:before;
      position: absolute;
      top: 28px;
      left: 10px;
      z-index: 1;
      font-size: 29px;
      color: $grey_color;
      @media (min-width: $screen-sm-min) {
        top: 30px;
      }
    }
    .base-input-coreoz {
      letter-spacing: 7px;
      padding-left: 55px;
      font-size: 0.85rem;
      padding-top: 12px;
      font-family: sans-serif;
    }
  }
}
.react-phone-number-input__icon {
  box-shadow: none;
  background: none;
  border: none;
}
.base-select-coreoz {
  @extend .hasicon;
  position: relative;
  select {
    color: $grey_color;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent !important;
    position: relative;
    z-index: 1;
  }
  &:before {
    font-size: 10px;
    @extend .icon-arrow-down:before;
    position: absolute;
    top: 38px;
    right: 10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    color: $grey_color;
  }
}

//---- checkbox

.a-input__checkbox {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 5px;
    cursor: pointer;
    height: 12px;
    z-index: 999;
    width: 100%;
  }

  .checkmark {
    position: relative;
    display: inline-block;
    height: 14px;
    width: 14px;
    border: 1px solid $green_color;
    background: $white_color;
    border-radius: 4px;
    top: 2px;
    left: -5px;
    &.carma {
      border-color: #00b1eb;
      &:after {
        border-color: #00b1eb;
      }
    }
    &.mutuaide {
      border-color: #00ab7f;
      &:after {
        border-color: #00ab7f;
      }
    }
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 6px;
    border: solid $green_color;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .a-label {
    width: auto;
    margin-left: 5px;
  }
}

.a-input__radio {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1;
  margin-top: -3px;

  input {
    position: absolute;
    opacity: 0;
    left: -5px;
    top: 5px;
    cursor: pointer;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark {
    border: 1px solid #2196f3;
    &.carma {
      border-color: #00b1eb;
      &:after {
        background-color: #00b1eb;
      }
    }
    &.mutuaide {
      border-color: #00ab7f;
      &:after {
        background-color: #00ab7f;
      }
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #2196f3;
  }

  .checkmark {
    position: absolute;
    top: 9px;
    left: 0;
    height: 12px;
    width: 12px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
}
/* Create the indicator (the dot/circle - hidden when not checked) */
/*  */

.PhoneInputCountrySelectArrow {
  @extend .hasicon;
  position: relative;
  font-size: 0;
  color: transparent;
  transform: inherit;
  width: 10px;
  opacity: 1;
  &:before {
    @extend .icon-arrow-down:before;
    font-size: 8px;
    position: absolute;
    top: 0;
    right: -5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    color: $grey_color;
  }
}

.div-tooltip {
  position: relative;
}

.tooltip {
  z-index: 2;
}
