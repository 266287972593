.a-button {
  &__primary {
    border: 0;
    background: $green_color;
    color: $white_color;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 25px;
    width: 100%;
    border-radius: 10px;
    @include boxshadow();
    a {
      color: $white_color;
      display: block;
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }
  }
  &__accordion {
    padding: 0;
    background: none;
    border: 0;
    color: $grey_color;
    margin-right: 10px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
    padding: 0 10px;
    span {
      display: inline-block;
      float: left;
      margin: 0;
    }
    i {
      font-size: 11px;
      float: right;
      @include transition();
    }
  }
  &__edit {
    background: $green_color;
    color: $white_color;
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    @include boxshadow();
    a {
      color: $white_color;
    }
  }
}

.a-button__primary[disabled] {
  background-color: $light-grey_color !important;
  a {
    pointer-events: none;
  }
}
