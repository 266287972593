.p-bin {
  position: relative;
  min-height: 100%;
  padding-bottom: 6rem;

  .o-header {
    height: 300px;
    @media (min-width: $screen-sm-min) {
      height: 500px;
    }
  }
  .base-label-input-coreoz {
    margin-left: 0;
  }
}

.personal-data {
  font-style: italic;
  font-size: small;
  color: $grey_color;

  a {
    color: #15c;
    text-decoration: underline;
  }
}

.covid-alert {
  margin-bottom: 20px;
  border: 0;
  background: $green_color;
  color: $white_color;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  padding: 10px 25px;
  width: 100%;
  @media (min-width: $screen-lg-min) {
    border-radius: 10px;
  }
  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}
