@font-face {
  font-family: 'icomoon';
  src: url('./../fonts/icons/icomoon.eot?wwdvhg');
  src: url('./../fonts/icons/icomoon.eot?wwdvhg#iefix') format('embedded-opentype'),
    url('./../fonts/icons/icomoon.ttf?wwdvhg') format('truetype'),
    url('./../fonts/icons/icomoon.woff?wwdvhg') format('woff'),
    url('./../fonts/icons/icomoon.svg?wwdvhg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.hasicon,
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: '\e903';
}
.icon-arrow-up {
  transform: rotate(180deg);
  &:before {
    content: '\e903';
  }
}

.icon-minus:before {
  content: '\e900';
}
.icon-plus:before {
  content: '\e901';
}
.icon-card:before {
  content: '\e902';
}
