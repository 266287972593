.a-text {
  &__small {
    color: $grey_color;
    font-size: 0.6rem;
    font-weight: 700;
    display: block;
    width: 49%;
    float: right;
    margin-bottom: 5px;
  }
  &__medium {
    margin: 0 0 8px 0;
    color: $grey_color;
    font-size: 0.7rem;
    font-weight: 700;
    display: block;
  }
  &__larg {
    margin: 0 0 8px 0;
    color: $grey_color;
    font-size: 1rem;
    font-weight: 700;
    display: block;
    text-transform: capitalize;
  }
  &__count {
    color: $light-grey_color;
    font-size: 2.5rem;
    font-weight: 700;
    display: inline-block;
    -webkit-text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    -moz-text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    margin: 0 30px;
  }
}
