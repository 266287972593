.o-beneficiary {
  &__toggle {
    margin-bottom: 12px;
    text-align: center;
  }
  &__list {
    > li {
      margin-bottom: 10px;
    }
  }
  &__certificate {
    margin-bottom: 30px;
    &-left {
      float: left;
      margin-right: 10px;
    }
    &-right {
      float: left;
    }
  }
}

//-- Toggle
.u-accordion {
  &__open {
    & + ul {
      height: 320px;
      @include transition();
      width: calc(100% + 10px);
      margin-left: -5px;
      padding: 0 5px;
      @media (min-width: $screen-tablet-max) {
        height: 335px;
      }
    }
  }
  &__opening {
    & + ul {
      overflow: hidden;
      height: 320px;
      @include transition();
      width: calc(100% + 10px);
      margin-left: -5px;
      padding: 0 5px;
      @media (min-width: $screen-tablet-max) {
        height: 335px;
      }
    }
  }
  &__close {
    & + ul {
      overflow: hidden;
      height: 0;
      @include transition();
    }
  }
}
