//--- Main container
.u-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 30px 0;
}

//--- margin
.u-margin-top-0 {
  margin-top: 0 !important;
}
.u-margin-top-10 {
  margin-top: 10px !important;
}
.u-margin-top-20 {
  margin-top: 20px !important;
}
.u-margin-left-0 {
  margin-left: 0 !important;
}
.u-margin-left-10 {
  margin-left: 10px !important;
}
.u-margin-bottom-30 {
  margin-bottom: 30px !important;
}

.u-margin-bottom-20 {
  margin-bottom: 20px !important;
}

//--- Text Color
.u-color {
  &__white {
    color: $white_color !important;
  }
}

//-- clearfix
.u-clearfix {
  clear: both;
  @include clearfix();
}

//-- float
.u-pull-left {
  float: left !important;
}
.u-pull-right {
  float: right !important;
}

.u-lowercase {
  text-transform: lowercase !important;
}

.u-capitalize {
  text-transform: capitalize !important;
}
.u-sansserif {
  font-family: sans-serif !important;
}

//-- required
.u-required:after {
  content: ' *';
  font-weight: bold;
  color: red;
  font-size: 0.7rem;
}
