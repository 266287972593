.o-header {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
  @media (min-width: $screen-sm-min) {
    height: 300px;
    margin-bottom: 30px;
  }
  @media (min-width: $screen-lg-min) {
    height: 500px;
    margin-bottom: 50px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    background: url('../img/courb.svg') no-repeat center bottom;
    background-size: 102%;
    z-index: 1;
    height: 61px;
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
}

.logo {
  cursor: pointer;
}
