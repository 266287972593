.base-container-input-coreoz .base-label-input-coreoz,
.a-label {
  display: inline-block;
  width: 100%;
  color: $grey_color;
  font-size: 0.7rem;
  font-weight: 300;
  margin: 0 0 5px 10px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.a-label__medium {
  display: inline-block;
  color: $grey_color;
  font-size: 0.9rem;
  font-weight: 300;
  margin: 3px 0 5px;
}
