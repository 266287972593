.rdrCalendarWrapper {
  width: 100%;
  border-radius: 10px;
  @include boxshadow();
}
.rdrMonth {
  width: 100%;
  padding: 0 !important;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrEndEdge + .rdrDayNumber span,
.rdrStartEdge + .rdrDayNumber span {
  position: relative;
  background: $white_color;
  color: $grey_color !important;
  border-radius: 50%;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  &:after {
    display: none !important;
  }
}
.rdrDayToday .rdrDayNumber span:after {
  border-left: 7px solid transparent;
  border-bottom: 7px solid $green_color;
  border-radius: 0;
  width: auto;
  height: auto;
  left: auto;
  right: 7px;
  transform: inherit;
  background: transparent;
}

.rdrDays {
  padding: 0 10px;
}
.rdrMonthAndYearPickers {
  justify-content: left;
  padding-left: 13px;
  color: $grey_color;
  font-weight: 700;
}
.rdrNextPrevButton {
  order: 2;
}
.rdrDayNumber span {
  color: $grey_color;
}

.rdrDayPassive {
  .rdrDayNumber span {
    color: $light-grey_color;
  }
}

.rdrPprevButton,
.rdrNextButton {
  @extend .hasicon;
  font-size: 12px;
  transform: rotate(90deg);
  color: $grey_color;
  background: none !important;
  margin: 0;
  &:after {
    @extend .icon-arrow-down:before;
  }
  i {
    display: none;
  }
}
.rdrNextButton {
  transform: rotate(-90deg);
  margin: 0 0.833em;
}
.rdrMonthAndYearWrapper {
  height: 47px;
}

/*-------- Coreoz date picker overide style --------*/
.rdtPicker {
  width: 100%;
  border-radius: 10px;
}
.rdtDay,
.dow,
td.rdtMonth,
td.rdtYear {
  font-size: 0.7rem;
  color: $grey_color;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: $light-grey_color;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: $green_color;
  border-radius: 25px;
}

.rdtPicker td.rdtToday:before {
  border-bottom: 7px solid $green_color;
}
.rdtSwitch {
  padding-left: 13px;
  color: $grey_color;
  font-weight: 700;
  padding: 0;
  font-size: 0.8rem;
}
