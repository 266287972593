.tip-container {
  max-width: 15rem;
  position: absolute;
  color: #94B6CE;
  top: 0;
  left: calc(100% - 1.7rem);
  cursor: pointer;
}

.tip-container.disabled {
  cursor: unset;
  color: #818a91;
}

.tip-container i {
  position: relative;
  display: inline-block;
  margin: auto;
  right: inherit;
}

.tip-container .tip {
  display: none;
  white-space: pre-wrap;
  width: 15rem;
  padding: 0.3rem;
  border: 1px solid #f7f7f7;
  position: absolute;
  bottom: calc(100% + 1rem);
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  background: #94B6CE;
  z-index: 999999999;
  color: white;

  @media (max-width: $screen-lg-min) {
    left: 90%;
    transform: translateX(-90%);
  }

  span {
    color: white;
  }
}

.tip-container .tip.override::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid currentColor;

  @media (max-width: $screen-lg-min) {
    left: 90%;
    transform: translateX(-90%);
  }
}

.tip-container i:hover + .tip {
  display: block;
}

.position_absolute {
  position: absolute;
}

.position_relative {
  position: relative;
}
