.o-footer {
  background: #e7e7e7;
  padding: 10px 0;
  text-align: center;

  &.fixed {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__sep {
    display: inline-block;
    position: relative;
    left: 5px;
    width: 3px;
    height: 3px;
    background: $black_color;
    top: -2px;
    border-radius: 50%;
  }

  .a-link {
    cursor: pointer;
  }
}
