.a-title {
  &__h1 {
  }
  &__h2 {
    width: 85%;
    text-transform: uppercase;
    display: block;
    color: $green_color;
    font-size: 0.9rem;
    margin: 30px 0 20px;
    font-weight: 700;
  }
  &__h3 {
    text-transform: uppercase;
    display: block;
    color: $grey_color;
    font-size: 0.9rem;
    margin: 30px 0 20px;
    font-weight: 700;
  }
  &__h4 {
    color: $grey_color;
    font-weight: 200;
    font-size: 0.6rem;
    margin-bottom: 5px;
    display: block;
    width: 49%;
    float: left;
  }
}
