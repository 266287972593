html {
  height: 100%;
  font-size: 20px;
  @media (max-width: $screen-phone) {
    font-size: 17px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 20px;
  }
}
body * {
  font-family: 'Raleway', sans-serif;
}

body,
#root {
  height: 100%;
}

#root {
  max-width: 1285px;
  margin: 0 auto;
  position: relative;
}

h1 {
  font-size: 1rem;
}

* {
  outline: none;
}

.app-container {
  position: relative;
  min-height: 100%;
}
