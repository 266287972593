@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin center-translate() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin section-transparant-calc() {
  background: $black-o_color;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin boxshadow {
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
}

@mixin transition {
  transition: all ease 0.4s;
}
